<template>
  <sign-page
    title-text="财政工资"
    :request="request"
    table-size="large"
    :dealFormData="dealFormData"
    :titleMenus="[{key: 'batch', label: '复制上月'}]"
    @clickTitleMenu="clickTitleMenu"
    :column-list="columnList"
    :search-parms="searchParms"
    :form-parms-update="formParms"
    :table-actions-fixed="true"
    :dealSearchParm="dealSearchParm"
    :initSearchData="{month: $datetime.format(new Date(), 'Y-M')}"
    :tableActionsWidth="100"
    :need-data-file="true">
    <wage-form
      @dataUpdate="dataUpdate"
      ref="wafeForm"
      title="复制上月"></wage-form>
  </sign-page>
</template>

<script>
import {
  workerWageRequest as request,
  wageJobTitleRequest,
  wageLevelRequest,
  performanceWorkerRequest
} from '@/api'

import WageForm from './cmp/form'

export default {
  components: {
    WageForm
  },
  computed: {
    searchParms () {
      return [{
        type: 'monthPicker',
        placeholder: '月份',
        key: 'month',
        default: new Date()
      }]
    },
    formParms: {
      get () {
        let data = [{
          type: 'monthPicker',
          label: '月份',
          key: 'month',
          check: {
            required: true
          }
        },
        {
          type: 'select',
          label: '员工',
          selectDatas: this.workers,
          key: 'performanceWorkerId',
          check: {
            required: true
          }
        },
        {
          type: 'input',
          label: '岗位工资',
          key: 'wageJobTitleWage',
          check: {
            rules: {
              type: 'number'
            }
          }
        },
        {
          type: 'input',
          label: '薪级工资',
          key: 'wageLevelWage',
          check: {
            rules: {
              type: 'number'
            }
          }
        },
        {
          type: 'input',
          label: '补贴工资',
          key: 'subsidy',
          check: {
            rules: {
              type: 'number'
            }
          }
        }]
        return data
      }
    },
    columnList: {
      get () {
        let data = [{
          title: '员工姓名',
          sort: true,
          field: 'workerName'
        },
        {
          title: '身份证号',
          sort: true,
          field: 'idNo'
        },
        {
          title: '岗位工资',
          sort: true,
          field: 'wageJobTitleWage'
        },
        {
          title: '薪级工资',
          sort: true,
          field: 'wageLevelWage'
        },
        {
          title: '补贴工资',
          sort: true,
          field: 'subsidy'
        },
        {
          title: '合计',
          sort: true,
          field: 'wage'
        },
        {
          title: '较上月',
          sort: true,
          field: 'compareWage'
        }]
        return data
      }
    }
  },
  created () {
    this.loadWorker()
    this.loadJobTitle()
    this.loadWageLevel()
  },
  methods: {
    dataUpdate () {
      this.pageVm.loadData()
    },
    clickTitleMenu (key, pageVm) {
      this.pageVm = pageVm
      if (key === 'batch') {
        this.$refs.wafeForm.formSubmit({
          startMonth: new Date(),
          endMonth: new Date(),
          allWorkers: 1,
          changeJobTitleType: -1,
          changeWageJobTitle: -1,
          changeWageLevel: -1,
          changeWageLevelType: 'up',
          changeSubsidy: -1
        }, () => {})
      }
    },
    async loadWorker () {
      let datas = await performanceWorkerRequest.get({})
      this.workers = datas.map(v => {
        return {
          key: v.id,
          label: v.name,
          data: v
        }
      })
    },
    async loadJobTitle () {
      let datas = await wageJobTitleRequest.get({})
      this.wageJobTitles = datas.map(v => {
        return {
          key: v.id,
          label: v.jobTitleType + ' ' + (v.mLevel ? v.mLevel + ' ' : '') + v.title,
          data: v
        }
      })
    },
    async loadWageLevel () {
      let datas = await wageLevelRequest.get({})
      this.wageLeves = datas.map(v => {
        return {
          key: v.id,
          label: v.jobTitleType + ' ' + v.name,
          data: v
        }
      })
    },
    async loadData (parm) {
      parm = Object.assign({}, parm)
      if (parm && parm.month) {
        let year = parm.month.split('-')[0]
        let month = parm.month.split('-')[1]
        if (year && month) {
          month = Number(month) - 1
          if (month === 0) {
            month = 12
            year = Number(year) - 1
          }
          parm.compareMonth = year + '-' + (month < 10 ? '0' + String(month) : month)
        }
      }
      let datas = await request.get(parm)
      datas.forEach(v => {
        v.compareWage = v.compareMonthWage === 0 || v.compareMonthWage ? v.wage - v.compareMonthWage : null
        if (v.compareWage && v.compareWage > 0) {
          v.compareWage = '+' + v.compareWage
        }
      })
      return datas
    },
    dealSearchParm (parm) {
      if (parm) {
        parm.month = parm.month && parm.month instanceof Date ? this.$datetime.format(parm.month, 'Y-M') : parm.month
      }
      return parm
    },
    dealFormData (parm) {
      parm.month = parm.month && parm.month instanceof Date ? this.$datetime.format(parm.month, 'Y-M') : parm.month
      return parm
    }
  },
  data () {
    return {
      workers: [],
      wageJobTitles: [],
      wageLeves: [],
      pageVm: null,
      request: Object.assign({}, request, {
        get: this.loadData,
        update: request.updateValue
      })
    }
  }
}
</script>
